import './AboutContent.css';

import aboutContent from '../../constants/aboutContent.json';

const AboutContent = () => {
  return (
    <>
      <div className='about-container'>
        <div className='about-content'>
          <ul className='about-content-list'>
            <li className='about-content-list-item' key={0}>
              <div className='about-content-profile-image-container'>
                <img
                  className='about-content-profile-image'
                  src={aboutContent.imageUrl}
                  alt='HERBELS'
                />
              </div>
            </li>
            <li
              className='about-content-list-item about-content-recommandation'
              key={1}
            >
              <p className='about-content-recommandation-text'>
                {aboutContent.recommandations[0]}
              </p>
              <p className='about-content-recommandation-author'>
                {aboutContent.recommendators[0]}
              </p>
            </li>
            <li className='about-content-list-item' key={2}>
              <label className='education-title'>Educaţie: </label>
              <ul className='education-list'>
                {aboutContent.education.map((element, index) => (
                  <li key={index}>
                    <label>{element}</label>
                  </li>
                ))}
              </ul>
            </li>
            <li className='about-content-list-item' key={3}>
              <label className='projects-title'>Proiecte: </label>
              <ul className='projects-list'>
                {aboutContent.projects.map((element, index) => {
                  const firstIndex = element.indexOf('„');
                  const secondIndex = element.indexOf('"');
                  return (
                    <li key={index}>
                      <label>
                        {element.substring(0, firstIndex)}
                        <b>{element.substring(firstIndex + 1, secondIndex)}</b>
                        {element.substring(secondIndex + 1)}
                      </label>
                    </li>
                  );
                })}
              </ul>
            </li>
            <li className='about-content-list-item' key={4}>
              <label className='recommandations-title'>Alte recomandări:</label>
              <ul className='recommandations-list'>
                <li className='recommandations-list-item' key={0}>
                  <p className='about-content-recommandation-text'>
                    {aboutContent.recommandations[1]}
                  </p>
                  <p className='about-content-recommandation-author'>
                    {aboutContent.recommendators[1]}
                  </p>
                </li>
                <li className='recommandations-list-item' key={1}>
                  <p className='about-content-recommandation-text'>
                    {aboutContent.recommandations[2]}
                  </p>
                  <p className='about-content-recommandation-author'>
                    {aboutContent.recommendators[2]}
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default AboutContent;
