import './StayHome.css';

import stayHome from '../../constants/stayHome.json';
import NavigationBar from '../../components/navigation-bar/NavigationBar';
import ScrollToTopButton from '../../components/scroll-to-top-button/ScrollToTopButton';
import ArtContent from '../../components/art-content/ArtContent';

const StayHome = () => {
  return (
    <>
      <NavigationBar />
      <ScrollToTopButton />
      <ArtContent artContentList={stayHome} />
    </>
  );
};

export default StayHome;
