import './WoodenChurchPage.css';
import woodenChurch from '../../constants/woodenChurch.json';

import NavigationBar from '../../components/navigation-bar/NavigationBar';
import ScrollToTopButton from '../../components/scroll-to-top-button/ScrollToTopButton';
import ArtContent from '../../components/art-content/ArtContent';

const WoodenChurch = () => {
  return (
    <>
      <NavigationBar />
      <ScrollToTopButton />
      <ArtContent artContentList={woodenChurch} />
    </>
  );
};

export default WoodenChurch;
