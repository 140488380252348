import './About.css';

import NavigationBar from '../../components/navigation-bar/NavigationBar';
import ScrollToTopButton from '../../components/scroll-to-top-button/ScrollToTopButton';
import AboutContent from '../../components/about-content/AboutContent';

const About = () => {
  return (
    <>
      <NavigationBar />
      <ScrollToTopButton />
      <AboutContent />
    </>
  );
};

export default About;
