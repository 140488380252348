import './Contact.css';

import ScrollToTopButton from '../../components/scroll-to-top-button/ScrollToTopButton';
import NavigationBar from '../../components/navigation-bar/NavigationBar';
import ContactContent from '../../components/contact-content/ContactContent';

const Contact = () => {
  return (
    <>
      <NavigationBar />
      <ScrollToTopButton />
      <ContactContent />
    </>
  );
};

export default Contact;
