import './App.css';
import { HashRouter as Router, useRoutes } from 'react-router-dom';

import Home from './pages/home/Home';
import About from './pages/about/About';
import VaultsDomesPage from './pages/vaults-domes/VaultsDomesPage';
import Inktober2019 from './pages/inktober2019/Inktober2019';
import Expo2011 from './pages/expo2011/Expo2011';
import WoodenChurch from './pages/woodenChurch/WoodenChurchPage';
import StayHome from './pages/stayHome/StayHome';
import Contact from './pages/contact/Contact';

const App = () => {
  const routes = useRoutes([
    { path: '/', element: <Home /> },
    { path: '/about', element: <About /> },
    { path: '/vaults-domes', element: <VaultsDomesPage /> },
    { path: '/inktober2019', element: <Inktober2019 /> },
    { path: '/expo2011', element: <Expo2011 /> },
    { path: '/wooden-church', element: <WoodenChurch /> },
    { path: '/stay-home', element: <StayHome /> },
    { path: '/contact', element: <Contact /> },
  ]);

  return routes;
};

const AppWrapper = () => {
  return (
    <Router>
      <App />
    </Router>
  );
};

export default AppWrapper;
