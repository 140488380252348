import './VaultsDomesPage.css';
import vaultsDomes from '../../constants/vaultsDomes.json';

import NavigationBar from '../../components/navigation-bar/NavigationBar';
import ScrollToTopButton from '../../components/scroll-to-top-button/ScrollToTopButton';
import ArtContent from '../../components/art-content/ArtContent';

const VaultsDomesPage = () => {
  return (
    <>
      <NavigationBar />
      <ScrollToTopButton />
      <ArtContent artContentList={vaultsDomes} />
    </>
  );
};

export default VaultsDomesPage;
