import { useState } from 'react';
import Dropdown from './Dropdown';
import './NavigationBar.css';

const NavigationBar = () => {
  const [showMyWorkDropdown, setShowMyWorkDropdown] = useState(false);

  const setWindowLocationHome = () => {
    window.location = '/';
  };

  const setWindowLocationAbout = () => {
    document.title = 'Herbels - About';
    window.location = '#/about';
  };

  const setWindowLocationContact = () => {
    document.title = 'Herbels - Contact';
    window.location = '#/contact';
  };

  const unhideMyWorkDropdown = (e) => {
    setShowMyWorkDropdown(true);
  };

  const hideMyWorkDropdown = (e) => {
    setShowMyWorkDropdown(false);
  };

  const myWorkDropdownList = [
    {
      title: '#stayhome #workingfromhome',
      location: '#/stay-home',
      documentTitle: 'Herbels - #stayhome #workingfromhome',
    },
    {
      title: 'Bisericile de lemn din sudul judeţului Vâlcea',
      location: '#/wooden-church',
      documentTitle: 'Herbels - Bisericile de lemn din sudul judeţului Vâlcea',
    },
    {
      title: 'Bolţi, cupole, cruci vâlcene',
      location: '#/vaults-domes',
      documentTitle: 'Herbels - Bolţi, cupole, cruci vâlcene',
    },
    {
      title: 'Expo 2011',
      location: '#/expo2011',
      documentTitle: 'Herbels - Expo 2011',
    },
  ];
  return (
    <>
      <div className='navigation-bar'>
        <ul className='navigation-bar-list'>
          <li className='navigation-bar-list-item'>
            <img
              className='logo'
              src={'https://i.postimg.cc/mryw2ZGh/logo.png'}
              alt='Herbels logo'
              onClick={setWindowLocationHome}
            />
          </li>
          <li
            className='navigation-bar-list-item'
            onClick={setWindowLocationAbout}
          >
            <label className='navigation-bar-label'>About</label>
          </li>
          <li
            className='navigation-bar-list-item'
            onMouseEnter={unhideMyWorkDropdown}
            onMouseLeave={hideMyWorkDropdown}
            onClick={
              showMyWorkDropdown ? hideMyWorkDropdown : unhideMyWorkDropdown
            }
          >
            <label className='navigation-bar-label'>My work</label>
            <Dropdown
              show={showMyWorkDropdown}
              dropdownList={myWorkDropdownList}
            ></Dropdown>
          </li>
          <li
            className='navigation-bar-list-item'
            onClick={setWindowLocationContact}
          >
            <label className='navigation-bar-label'>Contact</label>
          </li>
        </ul>
      </div>
    </>
  );
};

export default NavigationBar;
