import './ArtContent.css';

const ArtContent = ({ artContentList }) => {
  return (
    <>
      <div className='art-content-container'>
        <div className='art-content'>
          <ul className='art-content-list'>
            {artContentList.map((artContent) => (
              <li
                className={`art-content-list-item ${
                  artContent.alignment === 'horizontal'
                    ? 'art-content-list-item-horizontal'
                    : 'art-content-list-item-vertical'
                }`}
                key={artContent.id}
              >
                <img
                  className={`art-content-image ${
                    artContent.alignment === 'horizontal'
                      ? 'art-content-image-horizontal'
                      : 'art-content-image-vertical'
                  }`}
                  src={artContent.link}
                  alt={artContent.title}
                />
                <div
                  className={`art-content-image-text ${
                    artContent.alignment === 'horizontal'
                      ? 'art-content-image-text-horizontal'
                      : 'art-content-image-text-vertical'
                  }`}
                >
                  <p className='art-content-image-text-title'>
                    {artContent.title}
                  </p>
                  <p className='art-content-image-text-not-title'>
                    {artContent.size}
                  </p>
                  <p className='art-content-image-text-not-title'>
                    {artContent.technique}
                  </p>
                  <p className='art-content-image-text-not-title'>
                    {artContent.year}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default ArtContent;
