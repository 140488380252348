import './PageButton.css';

const PageButton = ({ style, location, src, alt, documentTitle }) => {
  const setWindowLocation = () => {
    document.title = documentTitle;
    window.location = location;
  };

  return (
    <>
      <img
        className='page-button-image'
        src={src}
        alt={alt}
        onClick={setWindowLocation}
        style={style}
      />
    </>
  );
};

export default PageButton;
