import './Home.css';

import NavigationBar from '../../components/navigation-bar/NavigationBar';
import ScrollToTopButton from '../../components/scroll-to-top-button/ScrollToTopButton';
import HomeContent from '../../components/home-content/HomeContent';

const Home = () => {
  return (
    <>
      <NavigationBar />
      <ScrollToTopButton />
      <HomeContent />
    </>
  );
};

export default Home;
