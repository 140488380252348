import './Dropdown.css';

const Dropdown = ({ show, dropdownList }) => {
  const handleClickOption = (e) => {
    document.title =
      dropdownList[e.target.attributes.value.nodeValue].documentTitle;
    window.location =
      dropdownList[e.target.attributes.value.nodeValue].location;
  };

  return (
    <>
      <div className='dropdown-space'></div>
      {show && (
        <ul className='dropdown-content'>
          {dropdownList.map((dropdownElement, index) => (
            <li
              className='dropdown-list-item'
              key={index}
              value={index}
              onClick={handleClickOption}
            >
              <label className='dropdown-label' value={index}>
                {dropdownElement.title}
              </label>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export default Dropdown;
