import './Expo2011.css';

import imagesExpo2011 from '../../constants/expo2011.json';
import NavigationBar from '../../components/navigation-bar/NavigationBar';
import ScrollToTopButton from '../../components/scroll-to-top-button/ScrollToTopButton';
import ArtContent from '../../components/art-content/ArtContent';

const Expo2011 = () => {
  return (
    <>
      <NavigationBar />
      <ScrollToTopButton />
      <ArtContent artContentList={imagesExpo2011} />
    </>
  );
};

export default Expo2011;
