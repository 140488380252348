import { useEffect, useState } from 'react';
import './ScrollToTopButton.css';

const ScrollToTopButton = () => {
  const [showButton, setShowButton] = useState(false);

  const handleScroll = () => {
    const position = window.pageYOffset;
    if (window.outerWidth < 640) {
      if (position > 210) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    } else {
      if (position > 110) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    }
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      {showButton && (
        <div className='scroll-to-top-button-container'>
          <button className='scroll-to-top-button' onClick={scrollToTop}>
            <img
              className='scroll-to-top-button-image'
              src='https://i.postimg.cc/QMzQ0f1k/arrow-up.png'
              alt='UP'
              onClick={scrollToTop}
            />
          </button>
        </div>
      )}
    </>
  );
};

export default ScrollToTopButton;
