import NavigationBar from '../../components/navigation-bar/NavigationBar';
import './Inktober2019.css';

const Inktober2019 = () => {
  return (
    <>
      <NavigationBar />
    </>
  );
};

export default Inktober2019;
