import './HomeContent.css';

import homeContent from '../../constants/homeContent.json';

import PageButton from '../page-button/PageButton';

const HomeContent = () => {
  return (
    <>
      <div className='home-container'>
        <div className='home-content'>
          <ul className='pages-list'>
            {homeContent.map((pageButton) => (
              <li key={pageButton.id} className='pages-list-item'>
                <PageButton
                  location={pageButton.location}
                  src={pageButton.src}
                  alt={pageButton.alt}
                  documentTitle={pageButton.documentTitle}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default HomeContent;
